<template>
    <div>
        <el-form ref="saveOrEditForm" :model="saveData" style="width:500px" :rules="rules" label-width="160px">
            <el-form-item label="sn号"  v-if="type=='update'">
                {{saveData.sn}}
            </el-form-item>
            <el-form-item label="活动名称"  v-if="type=='update'">
                {{saveData.activityName}}
            </el-form-item>
            <el-form-item label="sn号" prop="sn"  v-if="type!='update'">
                <FileUpload @change="getFile"></FileUpload>
            </el-form-item>
            <el-form-item label="代理/机构编号" prop="agentNo">
                <el-select style="width:100%"
                    v-model="agentNo"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入代理商/机构编号"
                    :remote-method="remoteMethod"
                    @change="getAgentName"
                    :loading="loading">
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.agentNo"
                    :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="代理/机构名称">
                {{saveData.agentName}}
            </el-form-item>
            
        </el-form>
        <el-row style="padding-left:160px">
            <el-button type="primary" @click="submitForm" :loading="loading">提交</el-button>
            <!-- <el-button type="primary" @click="cancel">取消</el-button> -->
        </el-row>
    </div>
</template>

<script>
    import {TerminalApi,AgentApi} from '@/api';
    import FileUpload from '@/components/FileUpload'
    export default {
        name: "CustomForm",
        props: {
            type: {
                type: String,
                default: 'insert',
            },
        },
        components:{
            FileUpload
        },
        data() {
            return {
                saveData: {agentNo:'',sn:''},
                loading: false,
                options:'',
                agentNo: '',
                policyList: [],
                activetyList: [],
                radio:'',
                rules:{
                    sn:[{ required: true, message: '请提交SN列表', trigger: 'change' }],
                    agentNo:[{ required: true, message: '请选择代理/机构编号', trigger: 'change' }],
                },
            }
        },
        watch: {

        },
        created(){
            if(this.$route.query.item){
                this.saveData = this.$route.query.item ? JSON.parse(this.$route.query.item) : {}
                this.saveData.agentNo = ''
                this.saveData.agentName = ''
            }
        
        },
        methods: {
            async remoteMethod(query){
                if(query!=''){
                let res = await AgentApi.queryAgentNameList(query)
                if(res.success){
                    this.options = res.data
                }
                } else{
                    this.options = []
                }
            },
            submitForm() {
                this.$refs.saveOrEditForm.validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if(this.type == 'update'){
                            TerminalApi.terminalInfo.snIssue(this.saveData)
                                .then(res=>{
                                    if(res.success){
                                        this.Message.success(res.message)
                                        this.$router.push({
                                            name:'terminalQuery'
                                        })
                                    }
                                    this.loading = false
                                })
                        } else if(this.type == 'batchUpdate'){
                            TerminalApi.terminalInfo.snBatchIssue(this.saveData)
                                .then(res=>{
                                    if(res.success){
                                        this.Message.success(res.message)
                                        this.$router.push({
                                            name:'terminalQuery'
                                        })
                                    }
                                    this.loading = false
                                })
                        }
                    } else {
                        return false;
                    }
                    });
                
            },
            cancel() {
                this.saveData = {};
                this.commitOnClose();
            },
            commitOnClose() {
                this.$emit('on-close');
            },
            getAgentName(val){
                this.saveData.agentNo = val.agentNo,
                this.agentNo = val.agentNo,
                this.saveData.agentName = val.userName
            },
            getFile(file){
                
                this.saveData.sn = file
                this.saveData.snFileUrl = file
            }
        }
    };
</script>

<style scoped>

</style>
